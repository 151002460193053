.contact-info {
  background-color: #f8f9fa;
  padding: 25px;
  border-radius: 5px;
  margin-bottom: 30px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.contact-info i {
  margin-right: 10px;
  color: #007bff;
}

.contact-form {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  border-radius: 3px;
  border: 1px solid #ddd;
  padding: 10px;
  font-size: 1rem;
}

.form-control:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn-primary {
  background-color: #007bff;
  border: none;
  border-radius: 3px;
  padding: 10px 25px;
  transition: background-color 0.3s;
}

.btn-primary:hover {
  background-color: #0069d9;
}

.is-invalid {
  border-color: #dc3545;
}

.invalid-feedback {
  color: #dc3545;
  font-size: 0.85rem;
  margin-top: 5px;
}

.thank-you-message {
  background-color: #d4edda;
  color: #155724;
  padding: 25px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px 15px;
  border-radius: 3px;
  margin-bottom: 20px;
}

.spinner-border {
  margin-right: 8px;
  width: 1rem;
  height: 1rem;
}

/* Make form responsive */
@media (max-width: 768px) {
  .contact-info,
  .contact-form {
    padding: 15px;
  }
}
